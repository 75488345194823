.productLogo {
	display: flex;
	gap: var(--hdsplus-spacing-03);
	align-items: center;

	&.small {
		gap: var(--hdsplus-spacing-02);
	}
}

.small {
	font-size: 14px;
	line-height: 18px;
}

.medium {
	font-size: 17px;
	line-height: 23px;
}

.large {
	font-size: 22px;
	line-height: 27px;
}
